.leaflet-map {
  height: 65%;
  max-height: 100vh;
}

.savetiles {
  display: none;
}

.gg-track {
  --ggs: 1.5;
  left: 10px;
  top: 10px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset 0 0 0 10px;
  border-radius: 100px;
}
.gg-track::after,
.gg-track::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-track::before {
  border-left: 4px solid;
  border-right: 4px solid;
  width: 18px;
  height: 2px;
  left: -6px;
  top: 2px;
}
.gg-track::after {
  width: 2px;
  height: 18px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  top: -6px;
}

.elevation-div {
  height: 25%;
  font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.lightblue-theme.elevation-control.elevation .background {
  background-color: rgb(255, 255, 255);
}

.lightblue-theme.elevation-control.elevation .axis line,
.lightblue-theme.elevation-control.elevation .axis path {
  stroke: #000;
}

.lightblue-theme.elevation-control.elevation .grid .tick line {
  stroke: rgba(0, 0, 0, 0.2);
}

.lightblue-theme.elevation-control.elevation .mouse-drag {
  fill: #aa397d3d;
}

.lightblue-theme.elevation-control.elevation .area {
  fill: #aa397d94 !important;
  stroke: #aa397d !important;
}

#details_cover_image,
#touristicContent_cover_image {
  height: 100%;
}

.menu-download {
  z-index: 400;
}

.over-all {
  z-index: 10001;
}

/* Fix Safari issue related to <summary> / <details> arrow */
details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
  display: none;
}
